<template>
  <div>
    <v-card class="ma-2 pa-2">
      <v-card-title class="pa-1">
        <span>{{ $t("employee.phones") }}</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-data-table
        dense
        fixed-header
        :loading-text="$t('loadingText')"
        :no-data-text="$t('noDataText')"
        :height="screenHeight"
        :loading="loading"
        :headers="headers"
        :items="phones"
        class="ma-1"
        style="border: 1px solid #aaa"
        item-key="id"
        multi-sort
        :server-items-length="server_items_length"
        :options.sync="dataTableOptions"
        :disable-pagination="true"
        :footer-props="{
          itemsPerPageOptions: [20, 50, 100, -1],
          itemsPerPageAllText: $t('itemsPerPageAllText'),
          itemsPerPageText: $t('itemsPerPageText'),
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-arrow-left',
          nextIcon: 'mdi-arrow-right',
        }"
        @update:page="updatePage"
        @update:items-per-page="updatePerPage"
      >
        <template v-slot:body.prepend>
          <tr class="py-0 my-0">
            <td class="py-0 my-0 dense"></td>
            <td class="py-0 my-0 dense">
              <v-text-field
                v-model="filter.name"
                type="text"
                clearable
                dense
                outlined
                hide-details
                @keyup.native.enter="getList()"
              ></v-text-field>
            </td>
            <td class="py-0 my-0 dense">
              <v-text-field
                v-model="filter.department_name"
                type="text"
                clearable
                dense
                outlined
                hide-details
                @keyup.native.enter="getList()"
              ></v-text-field>
            </td>
            <td class="py-0 my-0 dense">
              <v-text-field
                v-model="filter.position_name"
                type="text"
                clearable
                dense
                outlined
                hide-details
                @keyup.native.enter="getList()"
              ></v-text-field>
            </td>
            <td class="py-0 my-0 dense">
              <v-text-field
                v-model="filter.email"
                clearable
                dense
                outlined
                hide-details
                @keyup.native.enter="getList()"
              ></v-text-field>
            </td>
            <td class="py-0 my-0 dense">
              <v-text-field
                v-model="filter.phone"
                clearable
                type="number"
                dense
                outlined
                hide-details
                @keyup.native.enter="getList()"
              ></v-text-field>
            </td>
          </tr>
        </template>
        <template v-slot:[`item.email`]="{ item }">
          {{ item.email ? item.email : "" }}
        </template>
        <template v-slot:[`item.department_name`]="{ item }">
          {{ item.department_name ? item.department_name : "" }}
        </template>
        <template v-slot:[`item.position_name`]="{ item }">
          {{ item.position_name ? item.position_name : "" }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
const axios = require("axios").default;
const moment = require("moment");

export default {
  data() {
    return {
      loading: false,
      phones: [],
      filter: {},
      dialogHeaderText: "",
      dataTableOptions: { page: 1, itemsPerPage: 50 },
      page: 1,
      from: 0,
      server_items_length: -1,
    };
  },
  computed: {
    screenHeight() {
      return window.innerHeight - 175;
    },
    headers() {
      return [
        { text: "#", value: "id", align: "center", width: 50, sortable: false },
        { text: this.$t("user.name"), value: "name" },
        { text: this.$t("user.department_name"), value: "department_name" },
        { text: this.$t("user.position"), value: "position_name" },
        { text: this.$t("user.email"), value: "email" },
        { text: this.$t("user.phone"), value: "phone" },
      ];
    },
  },
  methods: {
    updatePage($event) {
      this.getList();
    },
    updatePerPage($event) {
      this.getList();
    },
    getList() {
      this.loading = true;
      axios
        .post(this.$store.state.backend_url + "api/phonebook", {
          pagination: this.dataTableOptions,
          filter: this.filter,
        })
        .then((response) => {
          this.phones = response.data.data;
          this.server_items_length = response.data.total;
          this.from = response.data.from;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
